import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PageHeader } from "../components/PageHeader";
import PostList from "../components/Blog/PostList";
import { decodeEntities } from "../utils/helpers";
import Layout from "../components/Layout";
import { Header } from "../components/Acf/Header";
import { GlobalCta } from "../components/Acf/GlobalCta";

const Blog = (props) => {
  const { data, pageContext, location } = props;
  const {
    wordpressPost: page = [],
    allWordpressPost,
    categoryFilter,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { yoast } = page ? page : { title: null, yoast: {}, acf: {} };
  const { showAuthor } = siteSettings.options;
  const { wordpressUrl } = wordpressWpSettings;
  const { title: siteTitle, date_format } = wordpressWpSettings;
  const { edges: posts } = allWordpressPost;

  return (
    <Layout
      location={location}
      wordpressUrl={wordpressUrl}
      theme="light"
      className="posts-archive"
    >
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Blog | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <Header title="News" theme="light" />
      <PostList
        posts={posts}
        title="Latest posts"
        pageContext={pageContext}
        categoryFilter={categoryFilter}
        siteMetadata={wordpressWpSettings}
        pathPrefix={"/blog/"}
        showAuthor={showAuthor}
        dateFormat={date_format}
      />
      <GlobalCta
        custom
        content={`<p><span class="h2">Contact us so we can help you today.</span></p>`}
        link={{ url: `/contact-us/`, title: `Contact Us` }}
      />
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "blog" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    wordpressCategory(slug: { eq: "uncategorised" }) {
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
